import { useCallback, useState } from 'react';
import { Button } from 'semantic-ui-react';

import {
  useGetRefetchAgentaiCampaignsJobsQuery,
  useRefetchAgentaiCampaignsMutation,
} from 'src/api/admin/agentai-campaigns';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';

const RefectchAgentaiCampaigns = () => {
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const { isLoading, mutateAsync } = useRefetchAgentaiCampaignsMutation();
  const { data: activeJob } = useGetRefetchAgentaiCampaignsJobsQuery();

  const loading = isLoading || activeJob;

  const onClick = useCallback(async () => {
    try {
      await mutateAsync();
    } catch (e: any) {
      apiErrorHandler(e, setApiMessage);
      setTimeout(() => setApiMessage(undefined), 3000);
    }
  }, [mutateAsync]);

  return (
    <Button
      color={apiMessage ? 'red' : 'blue'}
      onClick={!loading && !apiMessage ? onClick : undefined}
      loading={loading}
    >
      {apiMessage?.message || 'Refetch Campaigns'}
    </Button>
  );
};

export default RefectchAgentaiCampaigns;
