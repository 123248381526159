import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router-dom';
import { Form, Icon } from 'semantic-ui-react';

import { ListAgentaiCampaignsFilters } from 'src/api/admin/agentai-campaigns';
import Breadcrumbs from 'src/components/Breadcrumbs';
import PaginatedTable, { RenderProps } from 'src/components/PaginatedTable';
import useSearchQuery from 'src/hooks/useSearchQuery';
import { Container, Header, Row } from 'src/styles';
import AdminAgentaiCampaignsListBody from './AdminAgentaiCampaignsListBody';
import RefectchAgentaiCampaigns from './RefectchAgentaiCampaigns';

export const STATUS_FILTERS = [
  { key: 'active', value: true, text: 'Active' },
  { key: 'inactive', value: false, text: 'Inactive' },
];

const getFiltersFromQuery = (query: URLSearchParams): ListAgentaiCampaignsFilters => {
  let active;
  switch (query.get('active')) {
    case '1':
      active = true;
      break;

    case '0':
      active = false;
      break;

    default:
      active = undefined;
      break;
  }

  const search = query.get('search') || undefined;

  return { active, search };
};

const getSearchFromQuery = (query: URLSearchParams): string => {
  return query.get('search') || '';
};

const AdminAgentaiCampaignsList = () => {
  const { push, replace } = useHistory();
  const query = useSearchQuery();
  const [filters, setFilters] = useState<ListAgentaiCampaignsFilters>(() => getFiltersFromQuery(query));
  const [searchQuery, setSearchQuery] = useState<string>(() => getSearchFromQuery(query));

  useEffect(() => {
    if (!query.has('active')) {
      query.set('active', '1');
      replace({ search: query.toString() });
    }
  });

  useEffect(() => {
    setFilters(getFiltersFromQuery(query));
  }, [query]);

  const onSubmit = useCallback(() => {
    query.set('search', searchQuery);
    query.set('page', '1');
    push({ search: query.toString() });
    // NOTE: the useEffect hook above (watching query) will set the filter state for us
  }, [push, query, searchQuery]);

  return (
    <Container>
      <Helmet>
        <title>Campaigns - AgentAi - Admin | datascore</title>
      </Helmet>

      <Row style={{ alignItems: 'center', justifyContent: 'space-between' }}>
        <Header style={{ marginBottom: 0 }}>
          <Breadcrumbs crumbs={['Admin', 'AgentAi', 'Campaigns']} />
        </Header>
        <RefectchAgentaiCampaigns />
      </Row>

      <Form onSubmit={onSubmit}>
        <Form.Group>
          <Form.Dropdown
            label="Status"
            placeholder="All"
            selection
            clearable
            options={STATUS_FILTERS}
            value={typeof filters.active === 'undefined' ? '' : filters.active}
            onChange={(_, { value }) => {
              let active = '';
              switch (value) {
                case '':
                  active = '';
                  break;
                default:
                  active = Number(value).toString();
                  break;
              }

              query.set('active', active);
              query.set('page', '1');
              push({ search: query.toString() });

              // NOTE: the useEffect hook above (watching query) will set the filter state for us
            }}
          />

          <Form.Input
            label="Search"
            placeholder="by name"
            value={searchQuery || ''}
            onChange={(_, { value }) => setSearchQuery(value)}
            onBlur={onSubmit}
          />

          <Form.Button label="&nbsp;" icon>
            <Icon name="search" />
          </Form.Button>
        </Form.Group>
      </Form>

      <PaginatedTable
        headers={['Status', 'Name', 'Slug', 'Account(s)', 'Outbound DID', 'Prospect List UUID', '']}
        renderBody={(props: RenderProps) => <AdminAgentaiCampaignsListBody {...props} filters={filters} />}
      />
    </Container>
  );
};

export default AdminAgentaiCampaignsList;
