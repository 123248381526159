import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Label, Table } from 'semantic-ui-react';

import { ListAgentaiCampaignsFilters, useListAllAgentaiCampaignsQuery } from 'src/api/admin/agentai-campaigns';
import LinkWithDetail from 'src/components/LinkWithDetail';
import { RenderProps } from 'src/components/PaginatedTable';

type Props = RenderProps & {
  filters: ListAgentaiCampaignsFilters;
};

const AdminAgentaiCampaignsListBody = ({ limit, currentPage, setPageCount, filters }: Props) => {
  const { data: campaigns } = useListAllAgentaiCampaignsQuery({
    limit,
    offset: limit * (currentPage - 1),
    ...filters,
  });
  const history = useHistory();

  useEffect(() => {
    let count = 1;
    if (typeof campaigns !== 'undefined') {
      count = Math.ceil(campaigns.total / limit);
    }
    setPageCount(count);
  }, [campaigns, limit, setPageCount]);

  return (
    <>
      {campaigns?.data.map(campaign => {
        return (
          <Table.Row key={campaign.id}>
            <Table.Cell collapsing>
              <Label color={campaign.active ? 'green' : 'red'}>{campaign.active ? 'Active' : 'Inactive'}</Label>
            </Table.Cell>
            <Table.Cell>
              <LinkWithDetail
                to={`/admin/agentai/campaigns/${campaign.id}`}
                text={campaign.name}
                detail={campaign.uuid}
              />
            </Table.Cell>
            <Table.Cell>{campaign.slug}</Table.Cell>
            <Table.Cell>
              {campaign.account_access?.map(aa => (
                <Label>{aa.account?.name}</Label>
              ))}
            </Table.Cell>
            <Table.Cell>{campaign.outbound_did}</Table.Cell>
            <Table.Cell>{campaign.prospect_list_uuid}</Table.Cell>
            <Table.Cell textAlign="right" collapsing>
              <Button
                icon="pencil"
                color="blue"
                onClick={() => history.push(`/admin/agentai/campaigns/${campaign.id}`)}
              />
            </Table.Cell>
          </Table.Row>
        );
      })}
    </>
  );
};

export default AdminAgentaiCampaignsListBody;
