import { format, parse } from 'date-fns';
import { capitalize } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import { Helmet } from 'react-helmet-async';
import { Button, Form, Icon } from 'semantic-ui-react';

import { useGetUserProfileQuery } from 'src/api/auth';
import { AgentAttributionDataFilters } from 'src/api/reporting';
import Breadcrumbs from 'src/components/Breadcrumbs';
import Loading from 'src/components/Loading';
import NotFound from 'src/pages/not-found';
import { Container, Header as PageHeader } from 'src/styles';
import { ReportingTimeRange, ReportingTimeRanges } from 'src/types';
import { reportingTimeRangeToDateStrings } from 'src/utils';
import AgentAttributionTable from './AgentAttributionTable';
import P1ClosesTable from './P1ClosesTable';

const { REACT_APP_BETA_URL: betaURL } = process.env;

export type FilterType = AgentAttributionDataFilters & { timeRange: ReportingTimeRange };

const ReportingAgentAttribution = () => {
  const { data: user, isLoading } = useGetUserProfileQuery();
  const [filters, setFilters] = useState<FilterType>({
    view: 'agent',
    byCreatedDate: false,
    timeRange: 'this year',
    startDate: format(new Date().setMonth(0, 1), 'yyyy-MM-dd'),
    endDate: format(new Date(), 'yyyy-MM-dd'),
  });
  const [daterange, setDaterange] = useState<[Date | null, Date | null]>([
    new Date(new Date().setMonth(0, 1)),
    new Date(),
  ]);

  const onChangeTimeRange = useCallback((_, { value }) => {
    if (value === 'custom') {
      setFilters(prev => ({ ...prev, timeRange: value as ReportingTimeRange }));
    } else {
      const [startDate, endDate] = reportingTimeRangeToDateStrings(value as ReportingTimeRange);

      setDaterange([parse(startDate, 'yyyy-MM-dd', new Date()), parse(endDate, 'yyyy-MM-dd', new Date())]);

      setFilters(prev => ({ ...prev, timeRange: value as ReportingTimeRange, startDate, endDate }));
    }
  }, []);

  const onChangeDates = useCallback((d: [Date | null, Date | null]) => {
    setDaterange(d);
    const [startDate, endDate] = d;
    if (startDate !== null && endDate !== null) {
      setFilters(prev => ({
        ...prev,
        timeRange: 'custom' as ReportingTimeRange,
        startDate: format(startDate, 'yyyy-MM-dd'),
        endDate: format(endDate, 'yyyy-MM-dd'),
      }));
    }
  }, []);

  const onChangeView = useCallback((_, { value }) => {
    setFilters(prev => ({ ...prev, view: value }));
  }, []);

  const onDateColumn = useCallback((_, { value }) => {
    setFilters(prev => ({ ...prev, byCreatedDate: value }));
  }, []);

  const DOWNLOAD_URL = useMemo(() => {
    const url = new URL(`${betaURL}/api/reporting/agent-attribution/download`);

    const searchParams = new URLSearchParams();

    for (const [key, value] of Object.entries(filters)) {
      searchParams.append(key, value.toString());
    }

    url.search = searchParams.toString();

    return url.toString();
  }, [filters]);

  if (isLoading) return <Loading />;

  if (!user || !user.active_account) return <NotFound />;

  return (
    <Container>
      <Helmet>
        <title>Agent Attribution - Reporting | datascore</title>
      </Helmet>

      <PageHeader>
        <Breadcrumbs crumbs={['Reporting', 'Agent Attribution']} />
      </PageHeader>

      <Form>
        <Form.Group>
          <Form.Select
            label="Date Column"
            onChange={onDateColumn}
            options={[
              { key: 'call_date', text: 'Call Date', value: false },
              { key: 'created_date', text: 'Created Date', value: true },
            ]}
            value={filters.byCreatedDate}
          />

          <Form.Select
            label="Group By"
            onChange={onChangeView}
            options={[
              { key: 'agent', text: 'Agent', value: 'agent' },
              { key: 'campaign', text: 'Campaign', value: 'campaign' },
              { key: 'created', text: 'Created Date', value: 'created' },
              { key: 'p1', text: 'P1 Closes', value: 'p1' },
            ]}
            value={filters.view}
          />

          <Form.Field style={{ marginBottom: 0 }}>
            <label style={{ width: 230 }}>
              Time Range{' '}
              <span style={{ opacity: 0.4, fontSize: '0.9em', whiteSpace: 'nowrap' }}>
                ({filters.startDate} - {filters.endDate})
              </span>
            </label>

            <Form.Select
              onChange={onChangeTimeRange}
              options={ReportingTimeRanges.map(timeRange => ({
                key: timeRange,
                text: timeRange === 'this year' ? 'YTD' : capitalize(timeRange),
                value: timeRange,
              }))}
              value={filters.timeRange}
            />
          </Form.Field>

          <Form.Field>
            <div style={{ width: 240 }}>
              <label style={{ fontSize: 13, fontWeight: 700, lineHeight: 2 }}>Custom Range</label>
              <DatePicker
                selectsRange
                startDate={daterange[0]}
                endDate={daterange[1]}
                customInput={<Form.Input fluid icon="calendar" iconPosition="left" />}
                monthsShown={2}
                showPopperArrow={false}
                onChange={onChangeDates}
              />
            </div>
          </Form.Field>

          <Form.Field>
            <label style={{ fontSize: 13, fontWeight: 700, lineHeight: 1.8 }}>&nbsp;</label>
            <Button type="button" as="a" href={DOWNLOAD_URL} basic>
              <Icon name="download" /> Export CSV
            </Button>
          </Form.Field>
        </Form.Group>
      </Form>

      <div className="table-container-agent">
        {filters.view === 'p1' ? <P1ClosesTable filters={filters} /> : <AgentAttributionTable filters={filters} />}
      </div>
    </Container>
  );
};

export default ReportingAgentAttribution;
