import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { Grid, Header, Segment } from 'semantic-ui-react';

import { useGetAgentaiCampaignQuery } from 'src/api/admin/agentai-campaigns';
import Breadcrumbs from 'src/components/Breadcrumbs';
import Loading from 'src/components/Loading';
import NotFound from 'src/pages/not-found';
import { Container, Header as PageHeader, Row } from 'src/styles';
import AdminAgentaiCampaignGeneral from './AdminAgentaiCampaignGeneral';

const AdminAgentaiCampaignsEdit = () => {
  const { id } = useParams<{ id: string }>();
  const { data: campaign, isLoading } = useGetAgentaiCampaignQuery(Number(id));

  if (isLoading) {
    return <Loading />;
  }
  if (typeof campaign === 'undefined') {
    return <NotFound />;
  }

  return (
    <Container>
      <Helmet>
        <title>Edit Campaign - AgentAi - Admin | datascore</title>
      </Helmet>
      <Row style={{ alignItems: 'center', justifyContent: 'space-between' }}>
        <PageHeader style={{ marginBottom: 0 }}>
          <Breadcrumbs crumbs={['Admin', 'AgentAi', 'Campaigns', 'Edit']} />
        </PageHeader>
      </Row>
      <Segment>
        <Grid>
          <Grid.Row>
            <Grid.Column width={4}>
              <Header>General Settings</Header>
            </Grid.Column>
            <Grid.Column width={12}>
              <AdminAgentaiCampaignGeneral campaign={campaign} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </Container>
  );
};

export default AdminAgentaiCampaignsEdit;
