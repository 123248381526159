import isEmpty from 'lodash/isEmpty';
import { useState } from 'react';
import { Button, Form, Icon, Modal } from 'semantic-ui-react';

import { useAssignAgentaiCampaignsToAccountMutation } from 'src/api/admin/accounts';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import ApiMessage from 'src/components/ApiMessage';
import SelectAgentaiCampaigns from 'src/components/SelectAgentaiCampaigns';

type ValidationErrors = {
  campaignIds?: string;
};

type Props = {
  accountId: number;
  omitCampaignIds: number[];
};

const AssignAgentaiCampaignsToAccount = ({ accountId, omitCampaignIds }: Props) => {
  const [open, setOpen] = useState(false);
  const [apiMessage, setApiMessage] = useState<ApiMessageData | undefined>();
  const [errors, setErrors] = useState<ValidationErrors>({});
  const { isLoading, mutateAsync } = useAssignAgentaiCampaignsToAccountMutation();
  const [campaignIds, setCampaignIds] = useState<number[]>([]);

  const onClose = () => {
    setOpen(false);
    setCampaignIds([]);
    setApiMessage(undefined);
    setErrors({});
  };

  const validate = () => {
    const validationErrors: ValidationErrors = {};

    if (campaignIds.length === 0) {
      validationErrors.campaignIds = 'Please select at least one campaign';
    }

    setErrors(validationErrors);

    return validationErrors;
  };

  const onSubmit = async () => {
    if (!isEmpty(validate())) {
      return;
    }

    setApiMessage(undefined);

    try {
      await mutateAsync({ accountId, campaignIds });
      onClose();
    } catch (e: any) {
      apiErrorHandler(e, setApiMessage);
    }
  };

  return (
    <Modal
      size="tiny"
      open={open}
      onClose={onClose}
      onOpen={() => setOpen(true)}
      trigger={
        <Button color="blue">
          <Icon name="plus" />
          Assign Campaigns
        </Button>
      }
    >
      <Modal.Header>Assign AgentAi Campaigns</Modal.Header>
      <Modal.Content>
        <ApiMessage data={apiMessage} />

        <Form onSubmit={onSubmit}>
          <SelectAgentaiCampaigns
            label="Campagins"
            onChange={(_, { value }) => setCampaignIds(value as number[])}
            error={errors.campaignIds}
            multiple
            omitCampaignIds={omitCampaignIds}
            value={campaignIds}
          />

          <Form.Button loading={isLoading} color="blue" fluid>
            Assign
          </Form.Button>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default AssignAgentaiCampaignsToAccount;
