import { useQuery } from 'react-query';

import { AgentaiCampaign, PaginatedResponse } from 'src/types';
import { ApiResponse, http } from './http-common';

export type AgentaiCampaignsResponse = ApiResponse & {
  campaigns: PaginatedResponse<AgentaiCampaign>;
};

export type ListAgentaiCampaignsParams = {
  limit: number;
  offset: number;
};

export const useListAgentaiCampaignsQuery = (params: ListAgentaiCampaignsParams) => {
  return useQuery(['agentai', 'campaigns', params], async () => {
    const res = await http.get<AgentaiCampaignsResponse>('/api/agentai/campaigns', { params });
    return res.data.campaigns;
  });
};
