import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { Divider, Grid, Header, Icon, Label, Message, Segment, Tab } from 'semantic-ui-react';

import { useGetAccountQuery } from 'src/api/admin/accounts';
import Breadcrumbs from 'src/components/Breadcrumbs';
import Loading from 'src/components/Loading';
import PaginatedTable from 'src/components/PaginatedTable';
import NotFound from 'src/pages/not-found';
import { Container, Header as PageHeader } from 'src/styles';
import AdminAccountAgentaiCampaignsListBody from './AdminAccountAgentaiCampaignsListBody';
import AdminAccountAkkioModelsListBody from './AdminAccountAkkioModelsListBody';
import AdminAccountBandwidth from './AdminAccountBandwidth';
import AdminAccountBigqueryTablesListBody from './AdminAccountBigqueryTablesListBody';
import AdminAccountBillingConnectPro from './AdminAccountBillingConnectPro';
import AdminAccountBillingEnabled from './AdminAccountBillingEnabled';
import AdminAccountBillingGeneral from './AdminAccountBillingGeneral';
import AdminAccountBillingLeadscorePlus from './AdminAccountBillingLeadscorePlus';
import AdminAccountCommio from './AdminAccountCommio';
import AdminAccountHubspot from './AdminAccountHubspot';
import AdminAccountModelsListBody from './AdminAccountModelsListBody';
import AdminAccountOnboarding from './AdminAccountOnboarding';
import AdminAccountSettings from './AdminAccountSettings';
import AdminAccountTelnyx from './AdminAccountTelnyx';
import AdminAccountTwilio from './AdminAccountTwilio';
import AdminAccountUsersListBody from './AdminAccountUsersListBody';
import AdminAccountVoice from './AdminAccountVoice';
import ArchiveAccountButton from './ArchiveAccountButton';
import AssignAgentaiCampaignsToAccount from './AssignAgentaiCampaignsToAccount';
import AssignAkkioModelsToAccount from './AssignAkkioModelsToAccount';
import AssignModelsToAccount from './AssignModelsToAccount';
import AssignUsersToAccount from './AssignUsersToAccount';
import SubmitOnboardingToTwilioModal from './SubmitOnboardingToTwilioModal';

const AdminAccountsEdit = () => {
  const { id } = useParams<{ id: string }>();
  const { data: account, isLoading } = useGetAccountQuery(Number(id));

  if (isLoading) {
    return <Loading />;
  }

  if (typeof account == 'undefined') {
    return <NotFound />;
  }

  return (
    <Container>
      <Helmet>
        <title>Edit Account - Admin | datascore</title>
      </Helmet>

      <PageHeader>
        <Breadcrumbs crumbs={['Admin', 'Accounts', 'Edit']} />
      </PageHeader>

      <Segment>
        <Grid>
          <Grid.Row>
            <Grid.Column width={4}>
              <Header>General Settings</Header>
              <p>
                <Label>
                  {account.id}
                  <Label.Detail>Account ID</Label.Detail>
                </Label>
              </p>
              <p>
                <Label>
                  {account.bigqueryDatasetId}
                  <Label.Detail>Bigquery Dataset ID</Label.Detail>
                </Label>
              </p>
            </Grid.Column>
            <Grid.Column width={12}>
              <AdminAccountSettings account={account} />
            </Grid.Column>
          </Grid.Row>

          <Divider />

          <Grid.Row>
            <Grid.Column width={4}>
              <Header>Billing</Header>
              <AdminAccountBillingEnabled account={account} />
            </Grid.Column>
            <Grid.Column width={12} style={account.billing.enabled ? undefined : { opacity: 0.5 }}>
              <Tab
                panes={[
                  {
                    menuItem: 'General',
                    render: () => (
                      <Tab.Pane>
                        <AdminAccountBillingGeneral account={account} />
                      </Tab.Pane>
                    ),
                  },
                  {
                    menuItem: 'ConnectPro',
                    render: () => (
                      <Tab.Pane>
                        <AdminAccountBillingConnectPro account={account} />
                      </Tab.Pane>
                    ),
                  },
                  {
                    menuItem: 'LeadScore+',
                    render: () => (
                      <Tab.Pane>
                        <AdminAccountBillingLeadscorePlus account={account} />
                      </Tab.Pane>
                    ),
                  },
                ]}
              />
            </Grid.Column>
          </Grid.Row>

          <Divider />

          <Grid.Row>
            <Grid.Column width={4}>
              <Header>Hubspot</Header>
            </Grid.Column>

            <Grid.Column width={12}>
              <AdminAccountHubspot account={account} />
            </Grid.Column>
          </Grid.Row>

          <Divider />

          <Grid.Row>
            <Grid.Column width={4}>
              <Header>Voice</Header>
            </Grid.Column>

            <Grid.Column width={12}>
              <Tab
                panes={[
                  {
                    menuItem: 'General',
                    render: () => (
                      <Tab.Pane>
                        <AdminAccountVoice account={account} />
                      </Tab.Pane>
                    ),
                  },
                  {
                    menuItem: 'Bandwidth',
                    render: () => (
                      <Tab.Pane>
                        <AdminAccountBandwidth account={account} />
                      </Tab.Pane>
                    ),
                  },
                  {
                    menuItem: 'Commio',
                    render: () => (
                      <Tab.Pane>
                        <AdminAccountCommio account={account} />
                      </Tab.Pane>
                    ),
                  },
                  {
                    menuItem: 'Telnyx',
                    render: () => (
                      <Tab.Pane>
                        <AdminAccountTelnyx account={account} />
                      </Tab.Pane>
                    ),
                  },
                  {
                    menuItem: 'Twilio',
                    render: () => (
                      <Tab.Pane>
                        <AdminAccountTwilio account={account} />
                      </Tab.Pane>
                    ),
                  },
                ]}
              />
            </Grid.Column>
          </Grid.Row>

          <Divider />

          <Grid.Row>
            <Grid.Column width={4}>
              <Header>Onboarding</Header>
              {account.twilio_customer_profile ? (
                <Label>
                  <Icon name="check" />
                  Submitted to Twilio
                </Label>
              ) : (
                <SubmitOnboardingToTwilioModal account={account} />
              )}
            </Grid.Column>

            <Grid.Column width={12}>
              <AdminAccountOnboarding account={account} />
            </Grid.Column>
          </Grid.Row>

          <Divider />

          <Grid.Row>
            <Grid.Column width={4}>
              <Header>User Access</Header>
              <AssignUsersToAccount accountId={account.id} omitUserIds={account.user_access.map(u => u.user_id)} />
            </Grid.Column>
            <Grid.Column width={12}>
              <PaginatedTable
                headers={['Email', 'Role(s)', '']}
                renderBody={() => <AdminAccountUsersListBody userAccess={account.user_access} />}
              />
            </Grid.Column>
          </Grid.Row>

          <Divider />

          <Grid.Row>
            <Grid.Column width={4}>
              <Header>Bigquery Table Access</Header>
              {/* <AssignBigqueryTablesToAccount
                accountId={account.id}
                omitTableIds={(account.bigquery_table_access || []).map(t => t.table.id)}
              /> */}
            </Grid.Column>
            <Grid.Column width={12}>
              <PaginatedTable
                headers={['Display Name', 'Table ID', '']}
                renderBody={() => <AdminAccountBigqueryTablesListBody account={account} />}
              />
            </Grid.Column>
          </Grid.Row>

          <Divider />

          <Grid.Row>
            <Grid.Column width={4}>
              <Header>Engagement Model Access</Header>
              <AssignModelsToAccount
                accountId={account.id}
                omitModelIds={(account.model_access || []).map(ma => ma.model_id)}
              />
            </Grid.Column>
            <Grid.Column width={12}>
              <PaginatedTable
                headers={['Model', 'Rate (dollars per record)', '']}
                renderBody={() => <AdminAccountModelsListBody account={account} />}
              />
            </Grid.Column>
          </Grid.Row>

          <Divider />

          <Grid.Row>
            <Grid.Column width={4}>
              <Header>LeadScore+ Model Access</Header>
              <AssignAkkioModelsToAccount
                accountId={account.id}
                omitModelIds={(account.akkioModelAccess || []).map(ma => ma.akkioModelId)}
              />
            </Grid.Column>
            <Grid.Column width={12}>
              <PaginatedTable
                headers={['Model', 'Rate (dollars per record)', '']}
                renderBody={() => <AdminAccountAkkioModelsListBody account={account} />}
              />
            </Grid.Column>
          </Grid.Row>

          <Divider />

          <Grid.Row>
            <Grid.Column width={4}>
              <Header>AgentAi Campaign Access</Header>
              <AssignAgentaiCampaignsToAccount
                accountId={account.id}
                omitCampaignIds={(account.agentai_campaign_access || []).map(ca => ca.campaign_id)}
              />
            </Grid.Column>
            <Grid.Column width={12}>
              <PaginatedTable
                headers={['Name', 'Campaign DID', 'Prospect List UUID', '']}
                renderBody={() => <AdminAccountAgentaiCampaignsListBody account={account} />}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>

      <Segment color="red">
        <Message error visible>
          <Message.Header>Danger Zone!</Message.Header>
          <Message.Content>
            This action archives <strong>{account.name}</strong> and everything the account contains.{' '}
            <strong>There is no going back.</strong>
          </Message.Content>
        </Message>

        <ArchiveAccountButton accountId={account.id} />
      </Segment>
    </Container>
  );
};

export default AdminAccountsEdit;
