import { useEffect } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { Dropdown } from 'semantic-ui-react';

import { useGetUserProfileQuery } from 'src/api/auth';
import { AdminHeaderContainer, AdminHeaderMenu } from './styles';

const AdminHeader = () => {
  const { data: user } = useGetUserProfileQuery();
  const { pathname } = useLocation();

  useEffect(() => {
    if (user?.role === 'admin') {
      document.body.classList.add('role--admin');
    }

    return () => {
      document.body.classList.remove('role--admin');
    };
  }, [user?.role]);

  if (!user || user.role !== 'admin') return null;

  return (
    <AdminHeaderContainer id="admin-header">
      <AdminHeaderMenu>
        <span className="title">Admin</span>

        <NavLink to={`/admin/accounts`} className="item">
          Accounts
        </NavLink>
        <NavLink to={`/admin/agentai/campaigns`} className="item">
          AgentAi
        </NavLink>
        <NavLink to={`/admin/jobs`} className="item">
          Jobs
        </NavLink>
        <Dropdown
          text="LeadScore"
          direction="left"
          className={`item ${pathname.startsWith('/admin/leadscore') ? 'item--active' : ''}`}
        >
          <Dropdown.Menu>
            <Link to={`/admin/leadscore-plus/configs`} className="item">
              Configs
            </Link>
            <Link to={`/admin/leadscore-plus/akkio-models`} className="item">
              Models
            </Link>
          </Dropdown.Menu>
        </Dropdown>
        <NavLink to={`/admin/models`} className="item">
          Models
        </NavLink>
        <NavLink to={`/admin/pipelines`} className="item">
          Pipelines
        </NavLink>
        <Dropdown
          text="Reporting"
          direction="left"
          className={`item ${pathname.startsWith('/admin/reporting') ? 'item--active' : ''}`}
        >
          <Dropdown.Menu>
            <Link to={`/admin/reporting/v1`} className="item">
              Reporting v1
            </Link>
            <Link to={`/admin/reporting/v2`} className="item">
              Reporting v2
            </Link>
            <Link to={`/admin/reporting/bandwidth-call-stats`} className="item">
              Bandwidth Call Stats
            </Link>
            <Link to={`/admin/reporting/twilio-call-stats`} className="item">
              Twilio Call Stats
            </Link>
          </Dropdown.Menu>
        </Dropdown>
        <NavLink to={`/admin/users`} className="item">
          Users
        </NavLink>
        <NavLink to={`/admin/webhooks`} className="item">
          Webhooks
        </NavLink>
      </AdminHeaderMenu>
    </AdminHeaderContainer>
  );
};

export default AdminHeader;
