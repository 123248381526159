import { useCallback, useState } from 'react';
import { DropdownItemProps, Form, FormSelectProps, StrictFormSelectProps } from 'semantic-ui-react';

import { useListAllAgentaiCampaignsQuery } from 'src/api/admin/agentai-campaigns';

// NOTE: Even though FormSelectProps extends StrictFormSelectProps itself...
// for some reason, Omit<FormSelectProps, 'options'> alone was not providing type hints
// when using <SelectAgentaiCampaigns /> in other components.
type Props = Omit<FormSelectProps, 'options'> &
  Omit<StrictFormSelectProps, 'options'> & {
    omitTableIds?: number[];
  };

const SelectAgentaiCampaigns = ({ onChange: parentOnChange, omitCampaignIds = [], ...selectProps }: Props) => {
  const [search, setSearch] = useState('');
  const { data: campaigns, isLoading } = useListAllAgentaiCampaignsQuery({ limit: 1000, offset: 0 });

  const onChange = useCallback(
    (event, data) => {
      if (typeof parentOnChange !== 'undefined') {
        parentOnChange(event, data);
      }
      setSearch('');
    },
    [parentOnChange]
  );

  const options: DropdownItemProps[] =
    campaigns?.data
      .map(c => ({ key: c.id, text: c.name, value: c.id }))
      .filter(c => !omitCampaignIds.includes(c.key)) || [];

  return (
    <Form.Select
      {...selectProps}
      options={options}
      search
      searchQuery={search}
      onSearchChange={(_, { searchQuery }) => setSearch(searchQuery)}
      onChange={onChange}
      loading={isLoading}
    />
  );
};

export default SelectAgentaiCampaigns;
