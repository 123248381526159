import { formatDistanceToNow } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { Button, Icon, Label, Loader, Table } from 'semantic-ui-react';
import styled from 'styled-components';

import { useListenToVoiceRecordingMutation, useListVoiceRecordingsQuery } from 'src/api/voice-recordings';
import { RenderProps } from 'src/components/PaginatedTable';
import { VoiceRecording, VoiceRecordingType } from 'src/types';
import VoiceCallDeleteRecording from './VoiceCallDeleteRecording';
import VoiceCallRecordingActions from './VoiceCallRecordingActions';
import VoiceCallRecordingStar from './VoiceCallRecordingStar';

const AudioContainer = styled.div`
  position: relative;

  audio {
    position: absolute;
    top: 0;
    right: 50px;
    z-index: 1;
    height: 100%;
  }
`;

const AudioPlayer = ({ recording }: { recording: VoiceRecording }) => {
  const [isVisible, setIsVisible] = useState(false);
  const { mutateAsync } = useListenToVoiceRecordingMutation();

  const onClick = useCallback(async () => {
    setIsVisible(prev => !prev);
    try {
      await mutateAsync(recording.id);
    } catch (e) {
      console.error(e);
    }
  }, [mutateAsync, recording.id]);

  return (
    <AudioContainer>
      <Button icon onClick={onClick}>
        <Icon name={isVisible ? 'close' : 'play'} />
      </Button>
      {isVisible && <audio autoPlay controls src={recording.url} />}
    </AudioContainer>
  );
};

const ListVoiceCallRecordingsBody = ({
  limit,
  currentPage,
  setPageCount,
  type,
  phone,
  includeArchived,
  onlyFavorites,
}: RenderProps & { type: VoiceRecordingType; phone: string; includeArchived: boolean; onlyFavorites: boolean }) => {
  const { data: recordings, isLoading } = useListVoiceRecordingsQuery({
    limit,
    offset: limit * (currentPage - 1),
    type,
    phone,
    includeArchived,
    onlyFavorites,
  });

  useEffect(() => {
    let count = 1;
    if (typeof recordings !== 'undefined') {
      count = Math.ceil(recordings.total / limit);
    }
    setPageCount(count);
  }, [recordings, limit, setPageCount]);

  if (isLoading) {
    return (
      <Table.Row>
        <Table.Cell colSpan={9} style={{ textAlign: 'center' }}>
          <Loader inline active />
        </Table.Cell>
      </Table.Row>
    );
  }

  if (recordings?.count === 0) {
    return (
      <Table.Row>
        <Table.Cell colSpan={9}>No Recordings Found</Table.Cell>
      </Table.Row>
    );
  }

  return (
    <>
      {recordings?.data.map(r => (
        <Table.Row key={r.id}>
          <Table.Cell collapsing>
            <VoiceCallRecordingStar recording={r} />
          </Table.Cell>
          <Table.Cell collapsing>
            <Label>{r.call_sid}</Label>
          </Table.Cell>
          <Table.Cell>
            <span title={r.created_at}>
              {formatDistanceToNow(new Date(r.created_at), { addSuffix: true, includeSeconds: true })}
            </span>
          </Table.Cell>
          <Table.Cell>{r.phone_number}</Table.Cell>
          <Table.Cell>{r.type}</Table.Cell>
          <Table.Cell>{r.direction}</Table.Cell>
          <Table.Cell>{r.duration || 0} sec</Table.Cell>
          <Table.Cell>
            {!!r.listened_at ? (
              <Label title={r.listened_at}>
                {formatDistanceToNow(new Date(r.listened_at), { addSuffix: true, includeSeconds: true })}
              </Label>
            ) : (
              <Label color="blue">Unheard</Label>
            )}
          </Table.Cell>
          <Table.Cell textAlign="center" collapsing>
            <AudioPlayer recording={r} />
          </Table.Cell>
          <Table.Cell collapsing>
            <VoiceCallRecordingActions recording={r} />
            <VoiceCallDeleteRecording id={r.id} />
          </Table.Cell>
        </Table.Row>
      ))}
    </>
  );
};

export default ListVoiceCallRecordingsBody;
