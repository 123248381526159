import { useMutation, useQuery, useQueryClient } from 'react-query';

import { AgentaiCampaign, PaginatedResponse } from 'src/types';
import { ApiResponse, http } from '../http-common';

export type ListAgentaiCampaignsFilters = {
  active?: boolean;
  search?: string;
};

type ListAgentaiCampaignsParams = ListAgentaiCampaignsFilters & {
  limit: number;
  offset: number;
};

export type AgentaiCampaignsResponse = ApiResponse & {
  campaigns: PaginatedResponse<AgentaiCampaign>;
};

export type AgentaiCampaignResponse = ApiResponse & {
  campaign: AgentaiCampaign;
};

export const useListAllAgentaiCampaignsQuery = (params: ListAgentaiCampaignsParams) => {
  return useQuery(['admin/agentai', 'campaigns', params], async () => {
    const res = await http.get<AgentaiCampaignsResponse>(`/api/admin/agentai/campaigns`, { params });
    return res.data.campaigns;
  });
};

export const useGetAgentaiCampaignQuery = (id: number) => {
  return useQuery(['admin/agentai', 'campaigns', id], async () => {
    const res = await http.get<AgentaiCampaignResponse>(`/api/admin/agentai/campaigns/${id}`);
    return res.data.campaign;
  });
};

type UpdateAgentaiCampaignInput = {
  id: number;
  outbound_did: string | null;
  prospect_list_uuid: string | null;
};

export const useUpdateAgentaiCampaignMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ id, ...input }: UpdateAgentaiCampaignInput) => {
      const res = await http.post<AgentaiCampaignResponse>(`/api/admin/agentai/campaigns/${id}`, input);
      return res.data.campaign;
    },
    {
      onSuccess: (_, { id }) => {
        queryClient.invalidateQueries(['admin/agentai', 'campaigns', id]);
      },
    }
  );
};

export const useRefetchAgentaiCampaignsMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async () => {
      const res = await http.post<ApiResponse>('/api/admin/agentai/campaigns/refetch');
      return res.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['admin/agentai']);
      },
    }
  );
};

type GetRefetchAgentaiCampaignsJobsResponse = ApiResponse & {
  activeJob: boolean;
};

export const useGetRefetchAgentaiCampaignsJobsQuery = () => {
  return useQuery(
    ['admin/agentai', 'campaigns/refetch'],
    async () => {
      const res = await http.get<GetRefetchAgentaiCampaignsJobsResponse>(`/api/admin/agentai/campaigns/refetch`);
      return res.data.activeJob;
    },
    {
      retry: false,
      refetchInterval: 5e3,
    }
  );
};
