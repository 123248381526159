import { Table } from 'semantic-ui-react';

import { Account } from 'src/types';
import RemoveCampaignAccess from './RemoveCampaignAccess';

type Props = {
  account: Account;
};

const AdminAccountAgentaiCampaignsListBody = ({ account }: Props) => {
  if (account.agentai_campaign_access == null) {
    return null;
  }

  return (
    <>
      {account.agentai_campaign_access.map(access => {
        return (
          <Table.Row key={access?.campaign?.name}>
            <Table.Cell>{access?.campaign?.name}</Table.Cell>
            <Table.Cell>{access?.campaign?.outbound_did}</Table.Cell>
            <Table.Cell>{access?.campaign?.prospect_list_uuid}</Table.Cell>
            <Table.Cell textAlign="right" collapsing>
              <RemoveCampaignAccess accountId={account.id} campaignId={access?.campaign_id} />
            </Table.Cell>
          </Table.Row>
        );
      })}
    </>
  );
};

export default AdminAccountAgentaiCampaignsListBody;
